const TYPES = {
  I18nRootComponent: Symbol.for("I18nRootComponent"),
  Logger: Symbol.for("Logger"),
  Environment: Symbol.for("Environment"),
  CookieStorage: Symbol.for("CookieStorage"),
  HttpClient: Symbol.for("HttpClient"),
  Navigator: Symbol.for("Navigator"),
  Tracker: Symbol.for("Tracker"),

  WindowsMessenger: Symbol.for("WindowsMessenger"),
  CustomerWindowsMessenger: Symbol.for("CustomerWindowsMessenger"),

  RealTimeBoxProductionAuthCookieBasedFeatureToggle: Symbol.for("RealTimeBoxProductionAuthCookieBasedFeatureToggle"),
  PlanningAuthCookieBasedFeatureToggle: Symbol.for("PlanningAuthCookieBasedFeatureToggle"),
  CapacityAuthCookieBasedFeatureToggle: Symbol.for("CapacityAuthCookieBasedFeatureToggle"),
  StockSurveyTypeformAuthCookieBasedFeatureToggle: Symbol.for("StockSurveyTypeformAuthCookieBasedFeatureToggle"),
  AutomaticSelectionTypeformAuthCookieBasedFeatureToggle: Symbol.for(
    "AutomaticSelectionTypeformAuthCookieBasedFeatureToggle",
  ),
  CustomerInsightsAuthCookieBasedFeatureToggle: Symbol.for("CustomerInsightsAuthCookieBasedFeatureToggle"),

  RealTimeBoxProductionRouteFirewall: Symbol.for("RealTimeBoxProductionRouteFirewall"),
  PlanningRouteFirewall: Symbol.for("PlanningRouteFirewall"),
  CapacityRouteFirewall: Symbol.for("CapacityRouteFirewall"),

  ToLegacy: Symbol.for("ToLegacy"),
};

export { TYPES };
