import { useI18nMessage, useIntl } from "@lookiero/i18n-react";
import { FC, useCallback, useMemo } from "react";
import Country from "../../../../core/domain/country/model/Country";
import Input from "../../../../shared/ui/uiKit/molecules/input/Input";
import DatePicker from "../../../../shared/ui/uiKit/organisms/datePicker/DatePicker";
import Dropdown from "../../../../shared/ui/uiKit/organisms/dropdown/Dropdown";
import FormInputOption from "../../../../shared/ui/uiKit/_common/FormInputOption";
import { PlanningI18n, PLANNING_I18N_PREFIX } from "../../../componentLibrary/_i18n/PlanningI18n";
import { PlannedBoxesAssignment } from "../../../../core/projection/plannedBox/plannedBox";
import { CountryDropdown } from "../../../componentLibrary/organisms/countryDropdown/CountryDropdown";
import { Segment } from "../../../../core/projection/segment/segment";
import "./planned-boxes-filters.css";

interface PlannedBoxesFiltersProps {
  readonly plannedFor: Date | undefined;
  readonly country: Country[] | undefined;
  readonly psNumber: string | undefined;
  readonly boxNumber: string | undefined;
  readonly assignment: PlannedBoxesAssignment | undefined;
  readonly segment: Segment | undefined;
  readonly onPlannedForChanged: (date: Date) => void;
  readonly onCountryChanged: (countries: Country[]) => void;
  readonly onPsNumberChanged: (psNumber: string) => void;
  readonly onBoxNumberChanged: (psNumber: string) => void;
  readonly onAssignmentChanged: (assignment: PlannedBoxesAssignment) => void;
  readonly onSegmentChanged: (segment: Segment) => void;
}

const PlannedBoxesFilters: FC<PlannedBoxesFiltersProps> = ({
  plannedFor,
  country,
  psNumber = "",
  boxNumber = "",
  assignment,
  segment,
  onPlannedForChanged,
  onCountryChanged,
  onPsNumberChanged,
  onBoxNumberChanged,
  onAssignmentChanged,
  onSegmentChanged,
}) => {
  const { formatMessage } = useIntl();
  const plannedForDateLabel = useI18nMessage({ id: PlanningI18n.PLANNED_FOR_DATE_LABEL, prefix: PLANNING_I18N_PREFIX });
  const countryLabel = useI18nMessage({ id: PlanningI18n.COUNTRY_LABEL, prefix: PLANNING_I18N_PREFIX });
  const psNumberLabel = useI18nMessage({ id: PlanningI18n.PS_NUMBER_LABEL, prefix: PLANNING_I18N_PREFIX });
  const boxNumberLabel = useI18nMessage({ id: PlanningI18n.BOX_NUMBER_LABEL, prefix: PLANNING_I18N_PREFIX });
  const assignmentLabel = useI18nMessage({ id: PlanningI18n.ASSIGNMENT_LABEL, prefix: PLANNING_I18N_PREFIX });
  const segmentLabel = useI18nMessage({ id: PlanningI18n.SEGMENT_LABEL, prefix: PLANNING_I18N_PREFIX });

  const assignmentOptions: FormInputOption[] = useMemo(
    () => [
      {
        label: formatMessage({ id: `${PLANNING_I18N_PREFIX}${PlanningI18n.ASSIGNED}` }),
        value: PlannedBoxesAssignment.ASSIGNED,
      },
      {
        label: formatMessage({ id: `${PLANNING_I18N_PREFIX}${PlanningI18n.NON_ASSIGNED}` }),
        value: PlannedBoxesAssignment.NON_ASSIGNED,
      },
    ],
    [formatMessage],
  );

  const segmentOptions: FormInputOption[] = useMemo(
    () => [
      {
        label: formatMessage({ id: `${PLANNING_I18N_PREFIX}${PlanningI18n.SEGMENT_WOMEN}` }),
        value: Segment.WOMEN,
      },
      {
        label: formatMessage({ id: `${PLANNING_I18N_PREFIX}${PlanningI18n.SEGMENT_MEN}` }),
        value: Segment.MEN,
      },
    ],
    [formatMessage],
  );

  const handleOnPlannedForChanged = useCallback(
    (date: Date) => onPlannedForChanged(new Date(date.setHours(0, 0, 0, 0))),
    [onPlannedForChanged],
  );

  return (
    <section className="planned-boxes-filters" data-testid="planned-boxes-filters">
      <div className="planned-boxes-filters__filter">
        <DatePicker label={plannedForDateLabel} value={plannedFor} onChange={handleOnPlannedForChanged} />
      </div>
      <div className="planned-boxes-filters__filter">
        <CountryDropdown label={countryLabel} selected={country} onChange={onCountryChanged} />
      </div>
      <div className="planned-boxes-filters__filter">
        <Input placeholder={psNumberLabel} type="number" value={psNumber} onChange={onPsNumberChanged} />
      </div>
      <div className="planned-boxes-filters__filter">
        <Input placeholder={boxNumberLabel} type="number" value={boxNumber} onChange={onBoxNumberChanged} />
      </div>
      <div className="planned-boxes-filters__filter">
        <Dropdown
          label={assignmentLabel}
          options={assignmentOptions}
          value={assignment}
          isMulti
          onChange={onAssignmentChanged as (value?: string | string[] | null | undefined) => void}
        />
      </div>
      <div className="planned-boxes-filters__filter">
        <Dropdown
          label={segmentLabel}
          options={segmentOptions}
          value={segment}
          onChange={onSegmentChanged as (value?: string | string[] | null | undefined) => void}
        />
      </div>
    </section>
  );
};

export { PlannedBoxesFilters };
