import { PlannedBoxesByCriteriaView } from "../../../projection/plannedBox/searchPlannedBoxesByCriteria";
import { HttpGetFunction, HttpPostFunction } from "../../delivery/http/httpClient";
import { PlannedBoxDto, PreviewDto, toPlannedBox } from "./plannedBox";

interface HttpPlannedBoxesByCriteriaView extends PlannedBoxesByCriteriaView {}

interface HttpPlannedBoxesByCriteriaViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
  readonly httpLegacyGet: HttpGetFunction;
}

interface HttpPlannedBoxesByCriteriaViewFunction {
  (args: HttpPlannedBoxesByCriteriaViewFunctionArgs): HttpPlannedBoxesByCriteriaView;
}

const httpPlannedBoxesByCriteriaView: HttpPlannedBoxesByCriteriaViewFunction =
  ({ httpPost, httpLegacyGet }) =>
  async ({ plannedFor, signal }) => {
    try {
      const [plannedBoxes, previews] = await Promise.all([
        httpLegacyGet<PlannedBoxDto[]>({
          endpoint: `/labs/boxesForDate/${plannedFor.toISOString().replace(/T.*/, "")}`,
          signal,
        }),
        httpPost<PreviewDto[]>({
          endpoint: "/search-boxes-preview-of-ready-boxes",
          body: {},
          result: {
            error: [],
            success: (response) => response,
          },
          signal,
        }),
      ]);

      return plannedBoxes
        .map((plannedBox) =>
          toPlannedBox({
            ...plannedBox,
            preview: previews.find((preview) => preview.boxNumber === plannedBox._id),
          }),
        )
        .sort((aPlannedBox, anotherPlannedBox) => (aPlannedBox.boxNumber < anotherPlannedBox.boxNumber ? -1 : 1));
    } catch (e) {
      return [];
    }
  };

export { httpPlannedBoxesByCriteriaView };
