import { ProcessManagerFunction, ProcessManagerFunctionArgs } from "@lookiero/messaging";
import { BoxPreviewSent } from "../../boxPreview/model/BoxPreviewSent";
import {
  viewBoxPreviewByBoxId,
  ViewBoxPreviewByBoxIdResult,
} from "../../../projection/boxPreview/viewBoxPreviewByBoxId";
import cdnImageUrl from "../../../../ui/_util/cdnImageUrl";
import Environment from "../../../projection/environment/model/Environment";

interface PrefetchMediaWhenBoxPreviewSentFunctionArgs extends ProcessManagerFunctionArgs {
  readonly environment: Environment;
}

interface PrefetchMediaWhenBoxPreviewSentFunction
  extends ProcessManagerFunction<BoxPreviewSent, PrefetchMediaWhenBoxPreviewSentFunctionArgs> {}

const prefetchMediaWhenBoxPreviewSent: PrefetchMediaWhenBoxPreviewSentFunction =
  ({ queryBus, environment }) =>
  async ({ boxId }) => {
    const boxPreview: ViewBoxPreviewByBoxIdResult = await queryBus(viewBoxPreviewByBoxId({ boxId }));

    if (!boxPreview) {
      throw new Error(`BoxPreview for the provided box-id does not exist: ${boxId}`);
    }

    const {
      preview: {
        media: { perspectives, sizes, dpi },
      },
    } = environment;

    boxPreview.productVariants.forEach(({ media }) => {
      media.forEach(({ perspective, url }) => {
        if (perspectives.includes(perspective)) {
          Object.values(sizes).forEach((width) => {
            Object.values(dpi).forEach((dpi) => {
              const imageUrl = cdnImageUrl({ url, width, dpi });

              fetch(imageUrl, {
                credentials: "include",
                mode: "no-cors",
              });
            });
          });
        }
      });
    });
  };

export { prefetchMediaWhenBoxPreviewSent };
