import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const BOX_PREVIEW_SENT = "box_preview_sent";

interface BoxPreviewSentPayload {
  readonly aggregateId: string;
  readonly boxId: string;
  readonly selectionId: string;
}

interface BoxPreviewSent extends DomainEvent<typeof BOX_PREVIEW_SENT>, BoxPreviewSentPayload {}

interface BoxPreviewSentFunction {
  (payload: BoxPreviewSentPayload): BoxPreviewSent;
}

const boxPreviewSent: BoxPreviewSentFunction = ({ aggregateId, ...payload }) => ({
  ...domainEvent({ aggregateId, name: BOX_PREVIEW_SENT }),
  ...payload,
});

const isBoxPreviewSent = (event: DomainEvent<MessageName>): event is BoxPreviewSent => event.name === BOX_PREVIEW_SENT;

export type { BoxPreviewSent };
export { BOX_PREVIEW_SENT, boxPreviewSent, isBoxPreviewSent };
