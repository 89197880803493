import { useCallback, useMemo } from "react";
import { useI18nMessage } from "@lookiero/i18n-react";
import { Locale } from "@lookiero/i18n";
import { Data, Headers } from "react-csv/lib/core";
import { PLANNING_I18N_PREFIX, PlanningI18n } from "../../../componentLibrary/_i18n/PlanningI18n";
import { useSimulatedSearchPlannedBoxesByCriteria } from "../../../../core/infrastructure/projection/plannedBox/react/useSearchPlannedBoxesByCriteria";
import Country from "../../../../core/domain/country/model/Country";
import { PlannedBoxTag, PlannedBoxesAssignment } from "../../../../core/projection/plannedBox/plannedBox";
import { Segment } from "../../../../core/projection/segment/segment";

interface UseCSVPlanningBoxesFunctionArgs {
  readonly locale: Locale;
  readonly plannedFor: Date;
  readonly country: Country[] | undefined;
  readonly psNumber: string | undefined;
  readonly boxNumber: string | undefined;
  readonly tag: PlannedBoxTag[] | undefined;
  readonly assignment: PlannedBoxesAssignment | PlannedBoxesAssignment[] | undefined;
  readonly segment: Segment | undefined;
}

interface UseCSVPlanningBoxesFunctionReturn {
  readonly headers: Headers;
  readonly data: Data;
}

interface UseCSVPlanningBoxesFunction {
  (args: UseCSVPlanningBoxesFunctionArgs): UseCSVPlanningBoxesFunctionReturn;
}

const useCSVPlanningBoxes: UseCSVPlanningBoxesFunction = ({ locale, ...criteria }) => {
  const [plannedBoxes] = useSimulatedSearchPlannedBoxesByCriteria({ ...criteria, page: 0, perPage: 0 });

  const translate = useI18nMessage;
  const notAssignedPs = translate({ id: PlanningI18n.NOT_ASSIGNED_PS, prefix: PLANNING_I18N_PREFIX });

  const headers = useCallback(
    () =>
      [
        translate({ id: PlanningI18n.TABLE_HEADER_BOX, prefix: PLANNING_I18N_PREFIX }),
        translate({ id: PlanningI18n.TABLE_HEADER_PS, prefix: PLANNING_I18N_PREFIX }),
        translate({ id: PlanningI18n.TABLE_HEADER_NAME, prefix: PLANNING_I18N_PREFIX }),
        translate({ id: PlanningI18n.TABLE_HEADER_COUNTRY, prefix: PLANNING_I18N_PREFIX }),
        translate({ id: PlanningI18n.TABLE_HEADER_PREPARE_FOR, prefix: PLANNING_I18N_PREFIX }),
        translate({ id: PlanningI18n.TABLE_HEADER_STATUS, prefix: PLANNING_I18N_PREFIX }),
        translate({ id: PlanningI18n.TABLE_HEADER_BOX_COUNT, prefix: PLANNING_I18N_PREFIX }),
      ] as Headers,
    [translate],
  );

  const data = useMemo(
    () =>
      plannedBoxes
        ? Object.values(plannedBoxes)
            .flat()
            .map((plannedBox) => [
              plannedBox.boxNumber,
              plannedBox.psNumber || notAssignedPs,
              plannedBox.customer.fullName,
              plannedBox.country,
              plannedBox.plannedFor.toLocaleDateString(locale),
              plannedBox.statusLabel,
              plannedBox.customer.boxCount,
            ])
        : [],
    [locale, notAssignedPs, plannedBoxes],
  );

  return {
    headers: headers(),
    data,
  };
};

export { useCSVPlanningBoxes };
