import { command, Command } from "@lookiero/messaging";
import { Look } from "../model/Look";

const SEND_BOX_PREVIEW = "send_box_preview";

interface SendBoxPreviewPayload {
  readonly aggregateId: string;
  readonly boxId: string;
  readonly looks: Look[] | undefined;
  readonly selectionId: string;
  readonly selectionProductVariantIds: string[];
}

interface SendBoxPreview extends Command<typeof SEND_BOX_PREVIEW>, SendBoxPreviewPayload {}

interface SendBoxPreviewFunction {
  (payload: SendBoxPreviewPayload): SendBoxPreview;
}

const sendBoxPreview: SendBoxPreviewFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: SEND_BOX_PREVIEW }),
  ...payload,
});

export type { SendBoxPreview };
export { SEND_BOX_PREVIEW, sendBoxPreview };
