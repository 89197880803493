import { v4 as uuid } from "uuid";
import BoxPreviewI18n, { BOX_PREVIEW_I18N_PREFIX } from "../../../../ui/componentLibrary/_i18n/BoxPreviewI18n";
import { createToastNotification } from "../../../../shared/notifications/domain/notification/command/createToastNotification";
import { NotificationLevel } from "../../../../shared/notifications/domain/notification/model/notification";
import { ProcessManagerFunction } from "@lookiero/messaging";
import { BoxPreviewSent } from "../model/BoxPreviewSent";

interface CreateNotificationWhenBoxPreviewSentFunction extends ProcessManagerFunction<BoxPreviewSent> {}

const createNotificationWhenBoxPreviewSent: CreateNotificationWhenBoxPreviewSentFunction =
  ({ commandBus }) =>
  async () => {
    await commandBus(
      createToastNotification({
        aggregateId: uuid(),
        bodyI18nKey: `${BOX_PREVIEW_I18N_PREFIX}${BoxPreviewI18n.PREVIEW_SENT_NOTIFICATION}`,
        level: NotificationLevel.SUCCESS,
      }),
    );
  };

export { createNotificationWhenBoxPreviewSent };
