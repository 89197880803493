import Locale from "../../../domain/country/model/Locale";

interface SizeFormat {
  readonly size: string;
  readonly format: string;
  readonly length?: string | null;
}

interface Size {
  readonly id?: string;
  readonly lookiero: SizeFormat;
  readonly uk: SizeFormat;
  readonly it?: SizeFormat;
  readonly europe: SizeFormat;
  readonly visualOrder?: number;
}

type MainSizeAndLength = {
  readonly size: string;
  readonly length: string;
};

const NOT_INFORMED_LENGTH = "N/I";

const includesAnyComposedSize = (sizes: Size[]): boolean => sizes.some((size) => size.lookiero.length);

const lengthForSize = (size: Size): string => size.lookiero.length || NOT_INFORMED_LENGTH;
const sizeAndLength = (size: Size): MainSizeAndLength => ({
  size: size.lookiero.size,
  length: lengthForSize(size),
});

const mainSizesForComposedSizes = (sizes: Size[]): string[] => [...new Set(sizes.map((size) => size.lookiero.size))];

const lengthsForComposedSizes = (sizes: Size[]): string[] => [...new Set(sizes.map(lengthForSize))];

const sizeForSizeAndLength = ({ size, length }: MainSizeAndLength, sizes: Size[]): Size =>
  sizes.find((aSize) => aSize.lookiero.size === size && lengthForSize(aSize) === length) as Size;

const sizeForMainSize = ({ size, length }: MainSizeAndLength, sizes: Size[]): Size =>
  sizeForSizeAndLength({ size, length }, sizes) || sizes.find((aSize) => aSize.lookiero.size === size);

const sizeForLength = ({ size, length }: MainSizeAndLength, sizes: Size[]): Size =>
  sizeForSizeAndLength({ size, length }, sizes) || sizes.find((aSize) => lengthForSize(aSize) === length);

type SpecialSizeProps = {
  readonly locale: Locale;
  readonly size: Size;
};
const sizeFormat = ({ locale, size }: SpecialSizeProps): string => {
  const specialSizes: Partial<Record<Locale, SizeFormat>> = {
    [Locale.en_GB]: size.uk,
    [Locale.it_IT]: size.it,
  };

  return specialSizes[locale]?.format || size.lookiero.format;
};

export {
  includesAnyComposedSize,
  sizeAndLength,
  mainSizesForComposedSizes,
  lengthsForComposedSizes,
  sizeForMainSize,
  sizeForLength,
  sizeFormat,
};

export default Size;
