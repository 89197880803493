import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { SendBoxPreview } from "../command/sendBoxPreview";
import { boxPreviewSent } from "./BoxPreviewSent";
import {
  areDuplicatedLooks,
  areLooksIncludedInSelection,
  areLooksValid,
  areProductVariantsDuplicatedWithinALook,
  Look,
  maxLooksExceeded,
} from "./Look";

interface BoxPreview extends AggregateRoot {
  readonly boxId: string;
  readonly looks: Look[] | undefined;
}

const sendBoxPreviewHandler: CommandHandlerFunction<SendBoxPreview, BoxPreview> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { boxId, looks, selectionId, selectionProductVariantIds } = command;

    if (!looks || looks?.length === 0) {
      throw new Error("Looks are not created for this BoxPreview");
    }

    areLooksIncludedInSelection(looks, selectionProductVariantIds);
    maxLooksExceeded(looks);
    areLooksValid(looks);
    areDuplicatedLooks(looks);
    areProductVariantsDuplicatedWithinALook(looks);

    return {
      ...aggregateRoot,
      boxId,
      looks,
      domainEvents: [boxPreviewSent({ aggregateId: aggregateRoot.aggregateId, boxId, selectionId })],
    };
  };

export type { BoxPreview };
export { sendBoxPreviewHandler };
