type Look = string[];

const MAX_LOOKS = 4;
const VALID_LOOK_LENGTHS = [0, 2, 3, 4];

const areLooksIncludedInSelection = (looks: Look[] | undefined, selectionProductVariantIds: string[]) => {
  const looksAreNotIncludedInSelection = looks?.some((look) =>
    look.some((productVariantId) => !selectionProductVariantIds.includes(productVariantId)),
  );

  if (looksAreNotIncludedInSelection) {
    throw new Error("Looks' productVariants are not included in the Selection");
  }
};

const maxLooksExceeded = (looks: Look[] | undefined): void | never => {
  const exceeded = looks && looks.length > MAX_LOOKS;

  if (exceeded) {
    throw new Error(`Max looks exceeded (${MAX_LOOKS})`);
  }
};

const areLooksValid = (looks: Look[] | undefined): void | never => {
  const invalidLooksLength = looks && looks.some((look) => !VALID_LOOK_LENGTHS.includes(look.length));

  if (invalidLooksLength) {
    throw new Error("Invalid looks length");
  }
};

const areDuplicatedLooks = (looks: Look[] | undefined): void | never => {
  const duplicatedLooks = looks && looks.length !== new Set(looks).size;

  if (duplicatedLooks) {
    throw new Error("Duplicated looks");
  }
};

const areProductVariantsDuplicatedWithinALook = (looks: Look[] | undefined): void | never => {
  const duplicatedProductVariants = looks && looks.some((look) => look.length !== new Set(look).size);

  if (duplicatedProductVariants) {
    throw new Error("Duplicated product variants");
  }
};

export type { Look };
export {
  MAX_LOOKS,
  areLooksIncludedInSelection,
  maxLooksExceeded,
  areLooksValid,
  areDuplicatedLooks,
  areProductVariantsDuplicatedWithinALook,
};
