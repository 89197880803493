import { FC, ReactNode, useMemo } from "react";
import classNames from "classnames";
import { useI18nMessage } from "@lookiero/i18n-react";
import { StyleProfileComment } from "../../../../../core/projection/styleProfile/styleProfile";
import { StyleProfileCard } from "../../../../componentLibrary/organisms/styleProfileCard/StyleProfileCard";
import { StyleProfileCardTitle } from "../../../../componentLibrary/organisms/styleProfileCard/components/Title";
import Text, { TextVariant } from "../../../../../shared/ui/uiKit/atoms/text/Text";
import { CUSTOMER_I18N_PREFIX, CustomerI18n } from "../../../../componentLibrary/_i18n/CustomerI18n";
import "./comment.css";

interface StyleProfileCommentCardProps {
  readonly children: ReactNode;
  readonly locale: string;
  readonly title: string;
  readonly submittedOn: Date | undefined;
  readonly className?: string;
  readonly label?: string;
}
const StyleProfileCommentCard: FC<StyleProfileCommentCardProps> = ({
  className,
  children,
  label,
  locale,
  submittedOn,
  title,
}) => {
  const submittedOnFormatted = useMemo(
    () =>
      submittedOn
        ? Intl.DateTimeFormat(locale, {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }).format(submittedOn)
        : undefined,
    [locale, submittedOn],
  );

  return (
    <StyleProfileCard className={classNames("comment-card", className)} label={label}>
      <div className="comment-card__header">
        <StyleProfileCardTitle>{title}</StyleProfileCardTitle>
        {submittedOnFormatted && (
          <Text className="comment-card__header-date" variant={TextVariant.BODY_SMALL}>
            {submittedOnFormatted}
          </Text>
        )}
      </div>

      <Text variant={TextVariant.BODY}>{children}</Text>
    </StyleProfileCard>
  );
};

interface CommentProps {
  readonly comment: StyleProfileComment | null;
  readonly customerServiceComment?: StyleProfileComment | null;
  readonly locale: string;
  readonly showDate?: boolean;
  readonly className?: string;
}
const Comment: FC<CommentProps> = ({ comment, customerServiceComment, showDate = true, locale, className }) => {
  const commentTitleText = useI18nMessage({
    id: CustomerI18n.COMMENT_GENERAL_CURRENT,
    prefix: CUSTOMER_I18N_PREFIX,
  });

  const customerServiceCommentTitleText = useI18nMessage({
    id: CustomerI18n.COMMENT_CUSTOMER_SERVICE,
    prefix: CUSTOMER_I18N_PREFIX,
  });

  return comment || customerServiceComment ? (
    <div aria-label="comment" className={classNames("comment", className)}>
      {comment && comment.content && (
        <StyleProfileCommentCard
          className="comment__customer"
          label="customer-comment"
          locale={locale}
          submittedOn={showDate ? comment.submittedOn : undefined}
          title={commentTitleText}
        >
          {comment.content}
        </StyleProfileCommentCard>
      )}

      {customerServiceComment && customerServiceComment.content && (
        <StyleProfileCommentCard
          className="comment__customer-service"
          label="customer-service-comment"
          locale={locale}
          submittedOn={showDate ? customerServiceComment.submittedOn : undefined}
          title={customerServiceCommentTitleText}
        >
          {customerServiceComment.content}
        </StyleProfileCommentCard>
      )}
    </div>
  ) : null;
};
export { Comment };
