enum BoxPreviewStatus {
  SENT = "SENT",
  NOTIFIED = "NOTIFIED",
  EXPIRED = "EXPIRED",
  SUBMITTED = "SUBMITTED",
  /**
   * @deprecated
   *
   * It is not going to be possible to choose surprise anymore,
   * but due to we cannot rewrite the history we need to keep this status forever.
   */
  SURPRISE = "SURPRISE",
}

export { BoxPreviewStatus };
