import { isBoxPreviewSent } from "../../../../domain/boxPreview/model/BoxPreviewSent";
import { HttpBoxPreviewsSaveFunction } from "./HttpBoxPreviews";

interface HttpBoxPreviewsSendBoxPreviewFunction extends HttpBoxPreviewsSaveFunction {}

const httpBoxPreviewsSendBoxPreview: HttpBoxPreviewsSendBoxPreviewFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents, boxId, looks }) => {
    const boxClosed = domainEvents.find(isBoxPreviewSent);

    if (!boxClosed) {
      return;
    }

    await httpPost({
      endpoint: "/send-box-preview",
      body: {
        box_preview_id: aggregateId,
        box_id: boxId,
        looks,
      },
    });
  };

export { httpBoxPreviewsSendBoxPreview };
