import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { isEqual } from "lodash";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import Country from "../../../../domain/country/model/Country";
import { PlannedBox, PlannedBoxesAssignment, PlannedBoxTag } from "../../../../projection/plannedBox/plannedBox";
import {
  searchPlannedBoxesByCriteria,
  SearchPlannedBoxesByCriteriaResult,
} from "../../../../projection/plannedBox/searchPlannedBoxesByCriteria";
import { Segment } from "../../../../projection/segment/segment";

const weightForTag: (tag: PlannedBoxTag) => number = (tag) => (tag === PlannedBoxTag.COMPLETED ? 1 : 0);
const sortTags: (a: PlannedBoxTag, b: PlannedBoxTag) => number = (a, b) => weightForTag(a) - weightForTag(b);
const sortPlannedBoxes: (a: PlannedBox, b: PlannedBox) => number = (a, b) =>
  [a.tag, b.tag].every((tag) => tag === PlannedBoxTag.COMPLETED) ||
  [a.tag, b.tag].every((tag) => tag !== PlannedBoxTag.COMPLETED)
    ? a.boxNumber.localeCompare(b.boxNumber)
    : sortTags(a.tag, b.tag);

interface UseCountPlannedBoxesByCriteriaFunctionArgs {
  readonly plannedFor: Date;
  readonly country: Country[] | undefined;
  readonly psNumber: string | undefined;
  readonly boxNumber: string | undefined;
  readonly tag: PlannedBoxTag[] | undefined;
  readonly assignment: PlannedBoxesAssignment | PlannedBoxesAssignment[] | undefined;
  readonly segment: Segment | undefined;
}

interface UseSearchPlannedBoxesByCriteriaFunctionArgs extends UseCountPlannedBoxesByCriteriaFunctionArgs {
  readonly page: number;
  readonly perPage: number;
}

interface UseSimulatedSearchPlannedBoxesByCriteriaFunction {
  (args: UseSearchPlannedBoxesByCriteriaFunctionArgs): UseQueryFunctionResult<SearchPlannedBoxesByCriteriaResult>;
}

const useSimulatedSearchPlannedBoxesByCriteria: UseSimulatedSearchPlannedBoxesByCriteriaFunction = ({
  plannedFor,
  country,
  psNumber,
  boxNumber,
  assignment,
  tag,
  segment,
  page,
  perPage,
}) => {
  const [plannedBoxes, status] = useQuery<PlannedBox[]>({
    /**
     * This custom id should be removed once pagination and actual criteria-based search works
     * We are proxifying this request to labs-back-legacy and it returns all the planned-boxes
     */
    id: `${plannedFor}`,
    query: searchPlannedBoxesByCriteria({
      plannedFor,
      country,
      psNumber,
      boxNumber,
      assignment,
      tag,
      segment,
      page,
      perPage,
    }),
    contextId: MESSAGING_CONTEXT_ID,
    options: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    },
  });

  const filteredPlannedBoxes = useMemo(
    () =>
      plannedBoxes
        ?.filter((plannedBox) => {
          const countryFilter =
            country !== undefined
              ? Array.isArray(country)
                ? country.includes(plannedBox.country)
                : plannedBox.country === country
              : true;
          const boxNumberFilter = boxNumber !== undefined ? plannedBox.boxNumber.startsWith(boxNumber) : true;
          const psNumberFilter = psNumber !== undefined ? plannedBox.psNumber?.startsWith(psNumber) : true;
          const assignmentFilter =
            assignment !== undefined
              ? Array.isArray(assignment)
                ? assignment.reduce(
                    (acc, a) =>
                      acc
                        ? acc
                        : a === PlannedBoxesAssignment.ASSIGNED
                        ? Boolean(plannedBox.psNumber)
                        : !Boolean(plannedBox.psNumber),
                    false,
                  )
                : assignment === PlannedBoxesAssignment.ASSIGNED
                ? Boolean(plannedBox.psNumber)
                : !Boolean(plannedBox.psNumber)
              : true;
          const tagFilter = tag !== undefined ? tag.includes(plannedBox.tag) : true;
          const segmentFilter = segment !== undefined ? plannedBox.segment === segment : true;

          return assignmentFilter && countryFilter && boxNumberFilter && psNumberFilter && tagFilter && segmentFilter;
        })
        .sort(sortPlannedBoxes),
    [plannedBoxes, country, boxNumber, psNumber, assignment, tag, segment],
  );

  return [filteredPlannedBoxes, status];
};

interface UseViewPlannedBoxesSummaryByCriteriaFunction {
  (args: UseCountPlannedBoxesByCriteriaFunctionArgs): Record<PlannedBoxTag, number>;
}

const useViewPlannedBoxesSummaryByCriteria: UseViewPlannedBoxesSummaryByCriteriaFunction = (criteria) => {
  // page, perPage are unnecessary for the "real" use-case
  const [plannedBoxes] = useSimulatedSearchPlannedBoxesByCriteria({ ...criteria, page: 0, perPage: 0 });

  return useMemo(() => {
    const initialSummary = {
      [PlannedBoxTag.COMPLETED]: 0,
      [PlannedBoxTag.POSSIBLE_FRAUD]: 0,
      [PlannedBoxTag.PREVIEW_SENT]: 0,
      [PlannedBoxTag.PREVIEW_SUBMITTED]: 0,
      [PlannedBoxTag.READY]: 0,
    };

    return (
      plannedBoxes?.reduce(
        (acc, plannedBox) => ({ ...acc, [plannedBox.tag]: acc[plannedBox.tag] + 1 }),
        initialSummary,
      ) || initialSummary
    );
  }, [plannedBoxes]);
};

interface UseCountPlannedBoxesByCriteriaFunction {
  (args: UseCountPlannedBoxesByCriteriaFunctionArgs): number;
}

const useCountPlannedBoxesByCriteria: UseCountPlannedBoxesByCriteriaFunction = (criteria) => {
  // page, perPage are unnecessary for the "real" count use-case
  const [plannedBoxes] = useSimulatedSearchPlannedBoxesByCriteria({ ...criteria, page: 0, perPage: 0 });

  return useMemo(() => plannedBoxes?.length ?? 10, [plannedBoxes?.length]);
};

interface UseSearchPlannedBoxesByCriteriaFunction {
  (args: UseSearchPlannedBoxesByCriteriaFunctionArgs): Record<number, PlannedBox[]>;
}

const useSearchPlannedBoxesByCriteria: UseSearchPlannedBoxesByCriteriaFunction = ({ page, perPage, ...criteria }) => {
  const [pagedPlannedBoxes, setPagedPlannedBoxes] = useState<Record<number, PlannedBox[]>>({});
  const pagedPlannedBoxesRef = useRef(pagedPlannedBoxes);
  pagedPlannedBoxesRef.current = pagedPlannedBoxes;
  const criteriaRef = useRef(criteria);

  /**
   * Reset pagedPlannedBoxes when criteria changes
   */
  useEffect(() => {
    if (isEqual(criteriaRef.current, criteria)) {
      return;
    }

    criteriaRef.current = criteria;

    setPagedPlannedBoxes({});
    pagedPlannedBoxesRef.current = {};
  }, [criteria]);

  const [plannedBoxes] = useSimulatedSearchPlannedBoxesByCriteria({ ...criteria, page, perPage });
  // page is starting on 1 (not 0)
  const filteredPlannedBoxes = plannedBoxes?.slice((page - 1) * perPage, page * perPage);

  /**
   * Add new page to pagedPlannedBoxes
   */
  useEffect(() => {
    if (!filteredPlannedBoxes || isEqual(pagedPlannedBoxesRef.current[page], filteredPlannedBoxes)) {
      return;
    }

    setPagedPlannedBoxes({ ...pagedPlannedBoxesRef.current, [page]: filteredPlannedBoxes });
  }, [filteredPlannedBoxes, page]);

  return pagedPlannedBoxes;
};

export {
  useSearchPlannedBoxesByCriteria,
  useCountPlannedBoxesByCriteria,
  useViewPlannedBoxesSummaryByCriteria,
  useSimulatedSearchPlannedBoxesByCriteria,
  sortPlannedBoxes,
};
