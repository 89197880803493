import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { ShortlistFilterProjection } from "./shortlistFilter";

const LIST_SHORTLIST_FILTERS_BY_BOX_ID = "list_shortlist_filters_by_box_id";

interface ListShortlistFiltersByBoxIdPayload {
  readonly boxId: string;
}

interface ListShortlistFiltersByBoxId
  extends Query<typeof LIST_SHORTLIST_FILTERS_BY_BOX_ID>,
    ListShortlistFiltersByBoxIdPayload {}

interface ListShortlistFiltersByBoxIdFunction {
  (payload: ListShortlistFiltersByBoxIdPayload): ListShortlistFiltersByBoxId;
}

const listShortlistFiltersByBoxId: ListShortlistFiltersByBoxIdFunction = (payload) => ({
  ...query({ name: LIST_SHORTLIST_FILTERS_BY_BOX_ID }),
  ...payload,
});

type ListShortlistFiltersByBoxIdResult = ShortlistFilterProjection[] | null;

interface ShortlistFiltersByBoxIdViewArgs extends CancelableQueryViewArgs {
  readonly boxId: string;
}

interface ShortlistFiltersByBoxIdView {
  (args: ShortlistFiltersByBoxIdViewArgs): Promise<ListShortlistFiltersByBoxIdResult>;
}

interface ListShortlistFiltersByBoxIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: ShortlistFiltersByBoxIdView;
}

const listShortlistFiltersByBoxIdHandler: QueryHandlerFunction<
  ListShortlistFiltersByBoxId,
  ListShortlistFiltersByBoxIdResult,
  ListShortlistFiltersByBoxIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ boxId }) =>
    view({ boxId, signal });

export type { ShortlistFiltersByBoxIdView, ListShortlistFiltersByBoxId, ListShortlistFiltersByBoxIdResult };
export { LIST_SHORTLIST_FILTERS_BY_BOX_ID, listShortlistFiltersByBoxId, listShortlistFiltersByBoxIdHandler };
