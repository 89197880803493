import { useCallback } from "react";
import { useTracker } from "./useTracker";
import { Section } from "../Tracker";
import {
  StateFilteringValue,
  leafNodes,
} from "../../../ui/componentLibrary/organisms/shortlistFilters/ShortlistFilterState";
import { ShortlistFilterProjection } from "../../../core/projection/shortlistFilter/shortlistFilter";

interface TrackFilterFunctionArgs {
  readonly filters: ShortlistFilterProjection[];
  readonly state: StateFilteringValue[];
}
interface TrackFilterFunction {
  (args: TrackFilterFunctionArgs): void;
}

interface UseTrackFiltersFunctionArgs {
  readonly boxId?: string;
  readonly psId?: string;
  readonly section: Section;
  readonly userId?: string;
}
interface UseTrackFiltersFunction {
  (args: UseTrackFiltersFunctionArgs): TrackFilterFunction;
}

const useTrackFilters: UseTrackFiltersFunction = ({ section, psId, userId, boxId }) => {
  const tracker = useTracker();

  const trackFilter: TrackFilterFunction = useCallback(
    ({ filters, state }) => {
      if (!(tracker && section && psId && filters)) {
        return;
      }

      const leaves = leafNodes({ nodes: filters });
      const aliases = state
        .map(({ id }) => (leaves.find(({ id: leafId }) => id === leafId) as ShortlistFilterProjection).metadata?.alias)
        .toString();

      tracker.filters({
        boxId,
        filters: aliases,
        psId,
        section,
        userId,
      });
    },
    [boxId, psId, section, tracker, userId],
  );

  return trackFilter;
};

export { useTrackFilters };
