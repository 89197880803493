const SHORTLIST_I18N_PREFIX = "labs.shortlist.";

enum ShortlistI18n {
  CUSTOMER = "customer",
  SELECTION = "selection",
  ADD_TO_SELECTION = "product.add_to_selection",
  REMOVE_FROM_SELECTION = "product.remove_from_selection",
  ADD_TO_COMPARER = "product.add_to_comparer",
  COMPARE = "compare",
  PRODUCT_DESCRIPTION_AND_CHARACTERISTICS = "product.description_and_characteristics",
  PRODUCT_DESCRIPTION = "product.description",
  PRODUCT_CHARACTERISTICS = "product.characteristics",
  PRODUCT_OTHERS = "product.others",
  MODEL_MORPHOLOGY = "product.model_morphology",
  MODEL_TESTED_SIZE = "product.model.tested_size",
}

export { SHORTLIST_I18N_PREFIX };

export default ShortlistI18n;
