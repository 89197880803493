import invariant from "ts-invariant";
import { BoxPreview } from "../../../../domain/boxPreview/model/BoxPreview";
import {
  viewBoxPreviewById,
  ViewBoxPreviewById,
  ViewBoxPreviewByIdResult,
} from "../../../../projection/boxPreview/viewBoxPreviewById";
import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { BoxPreviewsGetFunction, BoxPreviewsSaveFunction } from "../../../../domain/boxPreview/model/BoxPreviews";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { httpBoxPreviewsSendBoxPreview } from "./httpBoxPreviewsSendBoxPreview";

interface ToDomainFunction {
  (box: ViewBoxPreviewByIdResult): BoxPreview;
}

const toDomain: ToDomainFunction = (boxPreview) => {
  invariant(boxPreview, "BoxPreview does not exist");

  return {
    aggregateId: boxPreview.id,
    boxId: boxPreview.boxId,
    looks: boxPreview.looks || [],
    domainEvents: [],
  };
};

interface HttpBoxPreviewsGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpBoxPreviewsGetFunction extends BoxPreviewsGetFunction<HttpBoxPreviewsGetFunctionArgs> {}

const getBoxPreview: HttpBoxPreviewsGetFunction =
  ({ queryBus }) =>
  async (aggregateId) =>
    toDomain(await queryBus<ViewBoxPreviewById, ViewBoxPreviewByIdResult>(viewBoxPreviewById({ id: aggregateId })));

interface HttpBoxPreviewsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpBoxPreviewsSaveFunction extends BoxPreviewsSaveFunction<HttpBoxPreviewsSaveFunctionArgs> {}

const saveBoxPreview: HttpBoxPreviewsSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) => {
    await httpBoxPreviewsSendBoxPreview({ httpPost })(aggregateRoot);
  };

export type { HttpBoxPreviewsSaveFunction };
export { getBoxPreview, saveBoxPreview };
