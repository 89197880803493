import { v4 as uuid } from "uuid";
import { useCallback } from "react";
import { Look } from "../../../../domain/boxPreview/model/Look";
import invariant from "ts-invariant";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { sendBoxPreview } from "../../../../domain/boxPreview/command/sendBoxPreview";
import { Logger } from "../../../../../shared/logging/Logger";

interface SendBoxPreviewFunction {
  (): Promise<void> | undefined;
}

interface UseSendBoxPreviewFunctionArgs {
  readonly selectionId: string | undefined;
  readonly boxId: string;
  readonly selectionProductVariantIds: string[];
  readonly looks?: Look[];
  readonly logger: Logger;
}

type UseSendBoxPreviewFunctionResult = [sendBoxPreview: SendBoxPreviewFunction, sendBoxPreviewStatus: CommandStatus];

interface UseSendBoxPreviewFunction {
  (args: UseSendBoxPreviewFunctionArgs): UseSendBoxPreviewFunctionResult;
}

const useSendBoxPreview: UseSendBoxPreviewFunction = ({
  selectionId,
  boxId,
  selectionProductVariantIds,
  looks,
  logger,
}) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const send = useCallback(async () => {
    invariant(selectionId, "Cannot send the box preview without a selectionId");

    try {
      await commandBus(sendBoxPreview({ aggregateId: uuid(), boxId, looks, selectionId, selectionProductVariantIds }));
    } catch (error) {
      logger.captureException(error as Error);
    }
  }, [selectionId, commandBus, boxId, looks, selectionProductVariantIds, logger]);

  return [send, status];
};

export { useSendBoxPreview };
