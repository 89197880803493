import { command, Command } from "@lookiero/messaging";
import { BoxPreviewStatus } from "../../boxPreview/model/BoxPreviewStatus";

const SELECT_PRODUCT_VARIANT = "select_product_variant";

interface SelectProductVariantPayload {
  readonly aggregateId: string;
  readonly boxId: string;
  readonly boxNumber: string;
  readonly productVariantId: string;
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
}

interface SelectProductVariant extends Command<typeof SELECT_PRODUCT_VARIANT>, SelectProductVariantPayload {}

interface SelectProductVariantFunction {
  (payload: SelectProductVariantPayload): SelectProductVariant;
}

const selectProductVariant: SelectProductVariantFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: SELECT_PRODUCT_VARIANT }),
  ...payload,
});

export type { SelectProductVariant };
export { SELECT_PRODUCT_VARIANT, selectProductVariant };
