/* eslint-disable @typescript-eslint/naming-convention */
import { BoxPreviewStatus } from "../../../domain/boxPreview/model/BoxPreviewStatus";
import Country from "../../../domain/country/model/Country";
import {
  PlannedBox,
  PlannedBoxCategoryTag,
  PlannedBoxLabel,
  PlannedBoxTag,
} from "../../../projection/plannedBox/plannedBox";
import { Segment } from "../../../projection/segment/segment";

const isCompleted = (plannedBoxLabels: PlannedBoxLabel[]) =>
  plannedBoxLabels.includes(PlannedBoxLabel.ERP_SERVIDO) || plannedBoxLabels.includes(PlannedBoxLabel.PS_DONE);

const isPossibleFraud = (plannedBoxCategoryTags: PlannedBoxCategoryTag[]) =>
  plannedBoxCategoryTags &&
  (plannedBoxCategoryTags.includes(PlannedBoxCategoryTag.POSSIBLE_FRAUD) ||
    plannedBoxCategoryTags.includes(PlannedBoxCategoryTag.CONFIRMED_FRAUD) ||
    plannedBoxCategoryTags.includes(PlannedBoxCategoryTag.INFORMATION_PENDING));

const plannedBoxTag = (plannedBox: PlannedBoxDto): PlannedBoxTag => {
  const labels = plannedBox.labels.split(",") as PlannedBoxLabel[];
  const categoryTags = JSON.parse(plannedBox.category_tags);

  if (
    plannedBox.preview?.boxPreviewStatus === BoxPreviewStatus.SUBMITTED ||
    plannedBox.preview?.boxPreviewStatus === BoxPreviewStatus.EXPIRED
  ) {
    return PlannedBoxTag.PREVIEW_SUBMITTED;
  }

  if (
    plannedBox.preview?.boxPreviewStatus === BoxPreviewStatus.SENT ||
    plannedBox.preview?.boxPreviewStatus === BoxPreviewStatus.NOTIFIED
  ) {
    return PlannedBoxTag.PREVIEW_SENT;
  }

  if (isCompleted(labels)) {
    return PlannedBoxTag.COMPLETED;
  }

  if (isPossibleFraud(categoryTags)) {
    return PlannedBoxTag.POSSIBLE_FRAUD;
  }

  return PlannedBoxTag.READY;
};

interface PreviewDto {
  readonly boxNumber: number;
  readonly boxPreviewId: string;
  readonly boxPreviewStatus: BoxPreviewStatus;
}

interface PlannedBoxDto {
  readonly _id: number;
  readonly json_user: {
    readonly apellidos: string;
    readonly nombre: string;
  };
  readonly _ps_id: number | null;
  readonly date_planned: string;
  readonly labels: string;
  readonly country_code: Country;
  readonly cantidad: number;
  readonly category_tags: string;
  readonly preview?: PreviewDto | undefined;
  readonly segment: Segment;
}

interface ToPlannedBoxFunction {
  (box: PlannedBoxDto): PlannedBox;
}

const toPlannedBox: ToPlannedBoxFunction = (box) => ({
  boxNumber: `${box._id}`,
  psNumber: box._ps_id ? `${box._ps_id}` : null,
  customer: {
    fullName: `${box.json_user.nombre} ${box.json_user.apellidos}`,
    boxCount: box.cantidad,
  },
  plannedFor: new Date(box.date_planned),
  country: box.country_code,
  statusLabel: box.labels.split(",").pop() as PlannedBoxLabel,
  tag: plannedBoxTag(box),
  segment: box.segment,
});

export type { PlannedBoxDto, PreviewDto };
export { toPlannedBox };
