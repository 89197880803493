import { ProcessManagerFunction } from "@lookiero/messaging";
import { BoxPreviewSent } from "../../boxPreview/model/BoxPreviewSent";
import { setLooks } from "../command/setLooks";

interface SetLooksWhenBoxPreviewSentFunction extends ProcessManagerFunction<BoxPreviewSent> {}

const setLooksWhenBoxPreviewSent: SetLooksWhenBoxPreviewSentFunction =
  ({ commandBus }) =>
  async ({ selectionId }) => {
    await commandBus(setLooks({ aggregateId: selectionId, looks: [] }));
  };

export { setLooksWhenBoxPreviewSent };
