import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { listShortlistFiltersByBoxId } from "../../../../projection/shortlistFilter/listShortlistFiltersByBoxId";
import { ShortlistFilterProjection } from "../../../../projection/shortlistFilter/shortlistFilter";

interface UseListShortlistFiltersByBoxIdFunctionArgs {
  readonly boxId?: string;
}

interface UseListShortlistFiltersByBoxIdFunction {
  (args: UseListShortlistFiltersByBoxIdFunctionArgs): UseQueryFunctionResult<ShortlistFilterProjection[]>;
}

const useListShortlistFiltersByBoxId: UseListShortlistFiltersByBoxIdFunction = ({ boxId }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: listShortlistFiltersByBoxId({ boxId: boxId as string }),
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: Boolean(boxId),
    },
  });

export { useListShortlistFiltersByBoxId };
