import { BoxPreviewStatus } from "./BoxPreviewStatus";

const boxPreviewStatusesFromSent: Readonly<BoxPreviewStatus[]> = [
  BoxPreviewStatus.SENT,
  BoxPreviewStatus.SUBMITTED,
  BoxPreviewStatus.EXPIRED,
  BoxPreviewStatus.SURPRISE,
] as const;

export { boxPreviewStatusesFromSent };
