import { createRoot } from "react-dom/client";
import { I18n } from "@lookiero/i18n-react";
import Cookies from "js-cookie";
import * as serviceWorker from "./serviceWorker";
import { bootstrap as messagingBootstrap } from "./core/container/bootstrap";
import bootstrap from "./core/container/container.config";
import { TYPES } from "./core/container/container.types";
import Environment from "./core/projection/environment/model/Environment";
import { Root } from "./ui/views/root/Root";
import FeatureToggles from "./ui/_featureToggle/FeatureToggles";
import FeatureToggle from "./shared/featureToggle/FeatureToggle";
import RouteFirewall from "./ui/_firewall/RouteFirewall";
import FetchHttpClient from "./shared/delivery/infrastructure/FetchHttpClient";
import HttpEnvironmentFetcher from "./core/infrastructure/projection/environment/model/HttpEnvironmentFetcher";
import { Tracker } from "./shared/tracking/Tracker";
import { Logger } from "./shared/logging/Logger";
import { Routes } from "./ui/_routing/Routing";
import "./shared/ui/uiKit/_tokens/index";
import "./ui/index.css";
import "./ui/componentLibrary/_uiKitTheme/index";

(async () => {
  const environment = await new HttpEnvironmentFetcher(new FetchHttpClient()).fetch();
  const authToken = Cookies.get("authtoken");

  const { Component: MessagingRoot } = messagingBootstrap({
    apiUrl: environment.labsBackUrl,
    legacyApiUrl: environment.labsBackLegacyUrl,
    authToken: authToken as string,
    environment,
  });
  const container = await bootstrap({ environment, authToken });

  const firewallConfig = {
    [Routes.PLANNING]: container.get<RouteFirewall>(TYPES.PlanningRouteFirewall),
    [Routes.REAL_TIME_BOX_PRODUCTION]: container.get<RouteFirewall>(TYPES.RealTimeBoxProductionRouteFirewall),
    [Routes.CAPACITY]: container.get<RouteFirewall>(TYPES.CapacityRouteFirewall),
  };

  const featureToggleConfig = {
    [FeatureToggles.REAL_TIME_BOX_PRODUCTION]: container.get<FeatureToggle>(
      TYPES.RealTimeBoxProductionAuthCookieBasedFeatureToggle,
    ),
    [FeatureToggles.STOCK_SURVEY_TYPEFORM]: container.get<FeatureToggle>(
      TYPES.StockSurveyTypeformAuthCookieBasedFeatureToggle,
    ),
    [FeatureToggles.AUTOMATIC_SELECTION_TYPEFORM]: container.get<FeatureToggle>(
      TYPES.AutomaticSelectionTypeformAuthCookieBasedFeatureToggle,
    ),
    [FeatureToggles.CUSTOMER_INSIGHTS]: container.get<FeatureToggle>(
      TYPES.CustomerInsightsAuthCookieBasedFeatureToggle,
    ),
  };

  const tracker = container.get<Tracker>(TYPES.Tracker);
  const logger = container.get<Logger>(TYPES.Logger);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(document.getElementById("root")!);
  root.render(
    <MessagingRoot>
      <Root
        I18nRootComponent={container.get<I18n>(TYPES.I18nRootComponent)}
        environment={container.get<Environment>(TYPES.Environment)}
        featureToggleConfig={featureToggleConfig}
        firewallConfig={firewallConfig}
        logger={logger}
        tracker={tracker}
      />
    </MessagingRoot>,
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
