import { v4 as uuid } from "uuid";
import { useCallback } from "react";
import { Logger } from "../../../../../shared/logging/Logger";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { BoxPreviewStatus } from "../../../../domain/boxPreview/model/BoxPreviewStatus";
import { selectProductVariant } from "../../../../domain/selection/command/selectProductVariant";

interface SelectProductVariantFunctionArgs {
  readonly productVariantId: string;
}

interface SelectProductVariantFunction {
  (args: SelectProductVariantFunctionArgs): Promise<void>;
}

type UseSelectProductVariantFunctionResult = [select: SelectProductVariantFunction, status: CommandStatus];

interface UseSelectProductVariantFunctionArgs {
  readonly selectionId: string | undefined;
  readonly boxNumber: string;
  readonly boxId: string;
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
  readonly logger: Logger;
}

interface UseSelectProductVariantFunction {
  (args: UseSelectProductVariantFunctionArgs): UseSelectProductVariantFunctionResult;
}

const useSelectProductVariant: UseSelectProductVariantFunction = ({
  selectionId,
  boxNumber,
  boxId,
  boxPreviewStatus,
  logger,
}) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const select: SelectProductVariantFunction = useCallback(
    async ({ productVariantId }) => {
      try {
        await commandBus(
          selectProductVariant({
            aggregateId: selectionId ?? uuid(),
            boxId,
            boxNumber,
            boxPreviewStatus,
            productVariantId,
          }),
        );
      } catch (error) {
        logger.captureException(error as Error);
      }
    },
    [boxId, boxNumber, boxPreviewStatus, commandBus, logger, selectionId],
  );

  return [select, status];
};

export { useSelectProductVariant };
