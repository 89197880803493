import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { BoxPreviewProjection } from "../../../../projection/boxPreview/boxPreview";
import { viewBoxPreviewByBoxId } from "../../../../projection/boxPreview/viewBoxPreviewByBoxId";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { isBoxPreviewSent } from "../../../../domain/boxPreview/model/BoxPreviewSent";

interface UseViewBoxPreviewByBoxIdFunctionArgs {
  readonly boxId: string | undefined;
}

interface UseViewBoxPreviewByBoxIdFunction {
  (args: UseViewBoxPreviewByBoxIdFunctionArgs): UseQueryFunctionResult<BoxPreviewProjection>;
}

const useViewBoxPreviewByBoxId: UseViewBoxPreviewByBoxIdFunction = ({ boxId }) =>
  useQuery({
    query: viewBoxPreviewByBoxId({ boxId: boxId as string }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: isBoxPreviewSent,
    options: { staleTime: Infinity, retry: 0, refetchOnWindowFocus: false, enabled: Boolean(boxId) },
  });

export { useViewBoxPreviewByBoxId };
