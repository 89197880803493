import { QueryStatus } from "@lookiero/messaging-react";
import { BoxPreviewStatus } from "../../../../../core/domain/boxPreview/model/BoxPreviewStatus";
import { useViewBoxPreviewByBoxId } from "../../../../../core/infrastructure/projection/boxPreview/react/useViewBoxPreviewByBoxId";

/**
 * This hook will return
 * - undefined if there is no BoxPreview (for the provided boxId) | while loading
 * - null if it's has not been yet submitted.
 * - selected productVariants' count (when submitted).
 */

interface UseViewBoxPreviewSelectionRateByBoxIdFunctionArgs {
  readonly boxId: string;
}

interface UseViewBoxPreviewSelectionRateByBoxIdFunction {
  (args: UseViewBoxPreviewSelectionRateByBoxIdFunctionArgs): number | null | undefined;
}

const useViewBoxPreviewSelectionRateByBoxId: UseViewBoxPreviewSelectionRateByBoxIdFunction = ({ boxId }) => {
  const [boxPreview, boxPreviewStatus] = useViewBoxPreviewByBoxId({ boxId });

  return boxPreviewStatus === QueryStatus.SUCCESS && boxPreview
    ? boxPreview.status === BoxPreviewStatus.SUBMITTED
      ? boxPreview.productVariants.filter((productVariant) => productVariant.chosen).length
      : null
    : undefined;
};

export { useViewBoxPreviewSelectionRateByBoxId };
