import {
  ListShortlistFiltersByBoxIdResult,
  ShortlistFiltersByBoxIdView,
} from "../../../projection/shortlistFilter/listShortlistFiltersByBoxId";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpShortlistFiltersByBoxIdView extends ShortlistFiltersByBoxIdView {}

interface HttpShortlistFiltersByBoxIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpShortlistFiltersByBoxIdViewFunction {
  (args: HttpShortlistFiltersByBoxIdViewFunctionArgs): HttpShortlistFiltersByBoxIdView;
}

const httpShortlistFiltersByBoxIdView: HttpShortlistFiltersByBoxIdViewFunction =
  ({ httpPost }) =>
  async ({ boxId, signal }) =>
    await httpPost<ListShortlistFiltersByBoxIdResult>({
      endpoint: "/list-shortlist-filters-by-box-id",
      body: { boxId },
      signal,
      result: {
        error: null,
        success: (response) => response,
      },
    });

export { httpShortlistFiltersByBoxIdView };
