import { PostMeWindowsMessengerChild } from "@lookiero/windows-messenger.js";
import { Container, decorate, injectable } from "inversify";
import { helpers, inversifyInterfaces } from "inversify-vanillajs-helpers";
import "reflect-metadata";
import packageInfo from "../../../package.json";
import { TYPES } from "./container.types";
import { EndpointFunction } from "@lookiero/i18n";
import { i18n } from "@lookiero/i18n-react";
import FetchHttpClient from "../../shared/delivery/infrastructure/FetchHttpClient";
import WindowNavigator from "../../shared/delivery/infrastructure/WindowNavigator";
import AuthCookieBasedFeatureToggle from "../../shared/featureToggle/AuthCookieBasedFeatureToggle";
import FeatureToggle from "../../shared/featureToggle/FeatureToggle";
import SentryLogger from "../../shared/logging/SentryLogger";
import CookieStorage from "../../shared/storage/infrastructure/domain/model/CookieStorage";
import { AsyncGtmTracker } from "../../shared/tracking/infrastructure/AsyncGtmTracker";
import FeatureToggleRouteFirewall from "../../ui/_firewall/FeatureToggleRouteFirewall";
import ToLegacy from "../../ui/views/toLegacy/ToLegacy";
import WindowsMessenger from "../infrastructure/integration/WindowsMessenger";
import isIframeEmbedded from "../infrastructure/integration/isIframeEmbedded";
import { fetchTranslations } from "../infrastructure/projection/translations/model/fetchTranslations";
import { translationExternalEndpoint } from "../infrastructure/projection/translations/model/translationEndpoint";
import Environment from "../projection/environment/model/Environment";
import { CustomerWindowsMessenger } from "../infrastructure/integration/customer/CustomerWindowsMessenger";

const inSingletonScopeBinding = <T>(binding: inversifyInterfaces.BindingInSyntax<T>): void =>
  void binding.inSingletonScope();

let container: Container;

interface BootstrapFunctionArgs {
  readonly environment: Environment;
  readonly authToken: string | undefined;
}

interface BootstrapFunction {
  (args: BootstrapFunctionArgs): Promise<Container>;
}

const bootstrap: BootstrapFunction = async ({ environment, authToken }) => {
  if (container) {
    return container;
  }

  container = new Container();

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const register = helpers.register(container as any);
  const registerConstantValue = helpers.registerConstantValue(container as any);
  const registerDynamicValue = helpers.registerDynamicValue(container as any);

  /**
   * We need to explicitly decorate this abstract class
   */
  decorate(injectable(), WindowsMessenger);

  registerConstantValue(TYPES.Environment, environment);

  /**
   * Init GTM Tracker
   */
  let tracker;
  if (environment.tracking) {
    try {
      tracker = await AsyncGtmTracker.init({
        project: environment.tracking.project,
        gtmId: environment.tracking.gtmId,
      });
    } catch (error) {}
  }
  registerConstantValue(TYPES.Tracker, tracker);

  /**
   * Init Sentry Logger
   */
  let logger;
  if (environment.logging) {
    logger = new SentryLogger({
      environment: process.env.NODE_ENV === "production" ? "web-PROD" : "web-DEV",
      release: packageInfo.version,
      project: environment.logging.sentryProject,
      publicKey: environment.logging.sentryPublicKey,
    });
  }
  registerConstantValue(TYPES.Logger, logger);

  registerDynamicValue(
    TYPES.HttpClient,
    (context) => {
      const { labsBackUrl } = context.container.get<Environment>(TYPES.Environment);

      const labsBackAuthHeaders =
        authToken !== undefined
          ? {
              Authorization: `Bearer ${authToken}`,
            }
          : undefined;

      return new FetchHttpClient(labsBackUrl, labsBackAuthHeaders);
    },
    inSingletonScopeBinding,
  );

  registerDynamicValue(
    TYPES.Navigator,
    (context) => {
      const { labsBackUrl } = context.container.get<Environment>(TYPES.Environment);

      return new WindowNavigator(labsBackUrl);
    },
    inSingletonScopeBinding,
  );

  registerConstantValue(TYPES.ToLegacy, ToLegacy);

  register(TYPES.CookieStorage, [], inSingletonScopeBinding)(CookieStorage);

  registerDynamicValue(TYPES.I18nRootComponent, (context) => {
    const {
      internationalization: { defaultLocale, externalEndpoint },
    } = context.container.get<Environment>(TYPES.Environment);

    const translations: EndpointFunction[] = [
      translationExternalEndpoint({
        translationsUrl: externalEndpoint,
        projects: [["labs"], ["inventory-catalog"], ["checkout"], ["style-profile"]],
        defaultLocale,
      }),
    ];

    return i18n({
      fetchTranslation: fetchTranslations({ translations }),
      contextId: "LabsI18n",
    });
  });

  /**
   * Windows Messenger integration
   */
  register(TYPES.WindowsMessenger, [], inSingletonScopeBinding)(PostMeWindowsMessengerChild);

  /**
   * FeatureToggles
   */
  registerDynamicValue(
    TYPES.RealTimeBoxProductionAuthCookieBasedFeatureToggle,
    (context) => {
      const environment = context.container.get<Environment>(TYPES.Environment);
      const cookieStorage = context.container.get<CookieStorage>(TYPES.CookieStorage);

      return new AuthCookieBasedFeatureToggle(cookieStorage, environment.toggleFeatures.isRealTimeBoxProductionEnabled);
    },
    inSingletonScopeBinding,
  );
  registerDynamicValue(
    TYPES.PlanningAuthCookieBasedFeatureToggle,
    (context) => {
      const environment = context.container.get<Environment>(TYPES.Environment);
      const cookieStorage = context.container.get<CookieStorage>(TYPES.CookieStorage);

      return new AuthCookieBasedFeatureToggle(cookieStorage, environment.toggleFeatures.isPlanningEnabled);
    },
    inSingletonScopeBinding,
  );
  registerDynamicValue(
    TYPES.StockSurveyTypeformAuthCookieBasedFeatureToggle,
    (context) => {
      const environment = context.container.get<Environment>(TYPES.Environment);
      const cookieStorage = context.container.get<CookieStorage>(TYPES.CookieStorage);

      return new AuthCookieBasedFeatureToggle(cookieStorage, environment.toggleFeatures.isStockSurveyTypeformEnabled);
    },
    inSingletonScopeBinding,
  );
  registerDynamicValue(
    TYPES.AutomaticSelectionTypeformAuthCookieBasedFeatureToggle,
    (context) => {
      const environment = context.container.get<Environment>(TYPES.Environment);
      const cookieStorage = context.container.get<CookieStorage>(TYPES.CookieStorage);

      return new AuthCookieBasedFeatureToggle(
        cookieStorage,
        environment.toggleFeatures.isAutomaticSelectionTypeformEnabled,
      );
    },
    inSingletonScopeBinding,
  );
  registerDynamicValue(
    TYPES.CapacityAuthCookieBasedFeatureToggle,
    (context) => {
      const environment = context.container.get<Environment>(TYPES.Environment);
      const cookieStorage = context.container.get<CookieStorage>(TYPES.CookieStorage);

      return new AuthCookieBasedFeatureToggle(cookieStorage, environment.toggleFeatures.isCapacityEnabled);
    },
    inSingletonScopeBinding,
  );
  registerDynamicValue(
    TYPES.CustomerInsightsAuthCookieBasedFeatureToggle,
    (context) => {
      const environment = context.container.get<Environment>(TYPES.Environment);
      const cookieStorage = context.container.get<CookieStorage>(TYPES.CookieStorage);

      return new AuthCookieBasedFeatureToggle(cookieStorage, environment.toggleFeatures.isCustomerInsightsEnabled);
    },
    inSingletonScopeBinding,
  );

  /**
   * Firewalls
   */
  register(TYPES.CustomerWindowsMessenger, [TYPES.WindowsMessenger], inSingletonScopeBinding)(CustomerWindowsMessenger);
  registerDynamicValue(
    TYPES.RealTimeBoxProductionRouteFirewall,
    (context) => {
      const featureToggles = [
        context.container.get<FeatureToggle>(TYPES.RealTimeBoxProductionAuthCookieBasedFeatureToggle),
      ];
      const fallbackComponent = isIframeEmbedded() ? () => null : ToLegacy;

      return new FeatureToggleRouteFirewall(featureToggles, fallbackComponent);
    },
    inSingletonScopeBinding,
  );
  registerDynamicValue(
    TYPES.PlanningRouteFirewall,
    (context) => {
      const featureToggles = [context.container.get<FeatureToggle>(TYPES.PlanningAuthCookieBasedFeatureToggle)];
      const fallbackComponent = () => null;

      return new FeatureToggleRouteFirewall(featureToggles, fallbackComponent);
    },
    inSingletonScopeBinding,
  );
  registerDynamicValue(
    TYPES.CapacityRouteFirewall,
    (context) => {
      const featureToggles = [context.container.get<FeatureToggle>(TYPES.CapacityAuthCookieBasedFeatureToggle)];
      const fallbackComponent = () => null;

      return new FeatureToggleRouteFirewall(featureToggles, fallbackComponent);
    },
    inSingletonScopeBinding,
  );

  return container;
};

export default bootstrap;
