import { ProductDetailProjection } from "../../../projection/product/productDetail";
import { ProductProjection } from "../../../projection/product/product";
import Sizing from "../../../domain/sizing/model/Sizing";
import { ProductVariantProjection } from "../../../projection/product/productVariant";
import { ProductAlertProjection, ProductAlertType } from "../../../projection/product/productAlert";
import Feature from "../../../projection/feature/model/Feature";
import FeatureType from "../../../domain/feature/model/FeatureType";
import FeatureUnit from "../../../domain/feature/model/FeatureUnit";
import Media from "../../../projection/media/model/Media";
import Size from "../../../projection/size/model/Size";
import Measurement from "../../../projection/measurement/model/Measurement";
import Color from "../../../projection/color/model/Color";
import Price from "../../../projection/price/model/Price";

interface ProductVariantDto {
  readonly id: string;
  readonly season: number;
  readonly recommended: boolean;
  readonly score: number;
  readonly color: Color;
  readonly size: Size;
  readonly price: Price;
  readonly media: Media[];
}

interface ProductDto {
  readonly id: string;
  readonly group: string;
  readonly title: string;
  readonly brand: {
    readonly name: string;
    readonly origin: string;
  };
  readonly sizing: string;
  readonly productVariants: ProductVariantDto[];
}

interface ProductFeatureDto {
  readonly id: string;
  readonly multiple: boolean;
  readonly type: string; // "NUMBER" | "STRING" | "BOOLEAN";
  readonly unit: string | null; // "CENTIMETERS" | "PERCENTAGE" | "null";
  readonly translationKey: string;
  readonly values: {
    readonly id: string;
    readonly value: string;
    readonly qualifier?: ProductFeatureDto | null;
    readonly translationKey: string;
  }[];
}

interface ProductAlertDto {
  readonly id: string;
  readonly name: string;
  readonly value: string;
  readonly type: string; // "RECOMMENDATION" | "SIZE" | "GARMENT";
}

interface TranslationKeyDto {
  readonly translationKey: string;
}

interface ModelDto {
  readonly id: string;
  readonly upperSize?: Size & TranslationKeyDto;
  readonly bottomSize?: Size & TranslationKeyDto;
  readonly shoeSize?: Size & TranslationKeyDto;
  readonly braSize?: Size & TranslationKeyDto;
  readonly weight?: Measurement & TranslationKeyDto;
  readonly height?: Measurement & TranslationKeyDto;
  readonly chestOutline?: Measurement & TranslationKeyDto;
  readonly waistOutline?: Measurement & TranslationKeyDto;
  readonly hipOutline?: Measurement & TranslationKeyDto;
  readonly fashionModelTestedSize?: Size & TranslationKeyDto;
}

interface ProductDetailDto extends ProductDto {
  readonly description: ProductFeatureDto[];
  readonly characteristics: ProductFeatureDto[];
  readonly others: ProductFeatureDto[] | null;
  readonly model: ModelDto | null;
  readonly alerts: ProductAlertDto[] | null;
}

interface ToProductVariantProjectionFunctionArgs {
  readonly productVariant: ProductVariantDto;
}

interface ToProductVariantProjectionFunction {
  (args: ToProductVariantProjectionFunctionArgs): ProductVariantProjection;
}

const toProductVariantProjection: ToProductVariantProjectionFunction = ({
  productVariant: { id, season, recommended, score, color, size, price, media },
}) => ({
  id,
  season,
  recommended,
  score,
  color,
  size,
  price,
  media: media as Media[],
});

interface ToFeatureProjectionFunctionArgs {
  readonly feature: ProductFeatureDto;
}

interface ToFeatureProjectionFunction {
  (args: ToFeatureProjectionFunctionArgs): Feature;
}

const toFeatureProjection: ToFeatureProjectionFunction = ({
  feature: { id, multiple, type, unit, translationKey, values },
}) => ({
  id,
  multiple,
  type: type as FeatureType,
  unit: unit as FeatureUnit,
  translationKey,
  values: values.map(({ id, value, qualifier, translationKey }) => ({
    id,
    value,
    qualifier: qualifier ? toFeatureProjection({ feature: qualifier }) : null,
    translationKey,
  })),
});

interface ToProductAlertProjectionFunctionArgs {
  readonly alert: ProductAlertDto;
}

interface ToProductAlertProjectionFunction {
  (args: ToProductAlertProjectionFunctionArgs): ProductAlertProjection;
}

const toProductAlertProjection: ToProductAlertProjectionFunction = ({ alert: { id, value, type } }) => ({
  id,
  value,
  type: type as ProductAlertType,
});

interface ToProductProjectionFunctionArgs {
  readonly product: ProductDto;
}

interface ToProductProjectionFunction {
  (args: ToProductProjectionFunctionArgs): ProductProjection<ProductVariantProjection>;
}

const toProductProjection: ToProductProjectionFunction = ({
  product: { id, group, title, brand, sizing, productVariants },
}) => ({
  id,
  group,
  title,
  brand,
  sizing: sizing as Sizing,
  productVariants: productVariants.map((productVariant) => toProductVariantProjection({ productVariant })),
});

interface ToProductDetailProjectionFunctionArgs {
  readonly productDetail: ProductDetailDto;
}

interface ToProductDetailProjectionFunction {
  (args: ToProductDetailProjectionFunctionArgs): ProductDetailProjection;
}

const toProductDetailProjection: ToProductDetailProjectionFunction = ({ productDetail }) => ({
  ...toProductProjection({ product: productDetail }), // as Product<ProductVariant>),
  description: productDetail.description.map((feature) => toFeatureProjection({ feature })),
  characteristics: productDetail.characteristics.map((feature) => toFeatureProjection({ feature })),
  others: productDetail.others ? productDetail.others.map((feature) => toFeatureProjection({ feature })) : [],
  model: productDetail.model ?? {},
  alerts: productDetail.alerts ? productDetail.alerts.map((alert) => toProductAlertProjection({ alert })) : [],
});

export type { ProductDetailDto, ProductFeatureDto };
export { toProductDetailProjection, toFeatureProjection };
