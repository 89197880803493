import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import {
  canMarkOrUnmarkProductVariantAsCandidate,
  CanMarkOrUnmarkProductVariantAsCandidateResult,
} from "../../../../projection/selection/canMarkOrUnmarkProductVariantAsCandidate";
import { MESSAGING_CONTEXT_ID } from "../../../../container/bootstrap";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { isBoxClosed } from "../../../../domain/box/model/boxClosed";
import { isBoxPreviewSent } from "../../../../domain/boxPreview/model/BoxPreviewSent";

const shouldInvalidate = (event: DomainEvent<MessageName>) => isBoxClosed(event) || isBoxPreviewSent(event);

interface UseCanMarkOrUnmarkProductVariantAsCandidateFunctionArgs {
  readonly boxNumber: string;
  readonly boxId: string;
}

interface UseCanMarkOrUnmarkProductVariantAsCandidateFunction {
  (
    args: UseCanMarkOrUnmarkProductVariantAsCandidateFunctionArgs,
  ): UseQueryFunctionResult<CanMarkOrUnmarkProductVariantAsCandidateResult>;
}

const useCanMarkOrUnmarkProductVariantAsCandidate: UseCanMarkOrUnmarkProductVariantAsCandidateFunction = ({
  boxNumber,
  boxId,
}) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: canMarkOrUnmarkProductVariantAsCandidate({ boxNumber, boxId }),
    invalidation: shouldInvalidate,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useCanMarkOrUnmarkProductVariantAsCandidate };
